import React, { Component } from 'react'
import "../css/skills.css"
import { Container, Row, Col } from 'react-bootstrap';
// import VisibilitySensor from "react-visibility-sensor";
// import { Spring } from 'react-spring/renderprops';

class Skills extends Component {
    constructor() {
        super();
        this.state = {
            height: 0,
            visible: false,
        }
    }

    componentDidMount(){
        this.setState({ height: this.divElement.clientHeight })
    }
    
    render() {
        // var height = this.state.height/1.85;
        return (
            // <VisibilitySensor
            //     partialVisibility
            //     offset = {{top: height, bottom: height}}>
            //     {({isVisible}) => (
            //     <Spring
            //         config={{duration: 800}}
            //         from={{opacity: 0 }} 
            //         to={{opacity: isVisible ? 1 : 0}}>
            //         {props =>
                    <Container id="skills" ref={(divElement) => {this.divElement = divElement}}>
                        <Row>
                            <Col md={12}>
                                <div className="skills-title">
                                    SKILLS
                                </div>
                            </Col>
                        </Row>
                        <div style={{backgroundColor: "#758b9b"}}>
                            <br></br>
                            <Row className="subtitles"> 
                                <Col xs={6} md={3}>
                                    <div>Front End
                                        <ul className="skills-list">
                                            <li>React</li>
                                            <li>HTML/CSS</li>
                                            <li>JavaScript</li>
                                        </ul>
                                    </div>
                                    <div>Back End
                                        <ul className="skills-list">
                                            <li>Node.js</li>
                                            <li>PHP</li>
                                            <li>Python</li>
                                            <li>Java</li>
                                            <li>C/C++</li>
                                            <li>MySQL</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={6} md={3}>
                                    <div>Other
                                        <ul className="skills-list">
                                            <li>Git/Github</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="skills-text">
                                        Over the past five years I have 
                                        actively been working on my coding
                                        and problem solving skills. 
                                        I have spent countless hours learning
                                        new languages, programs and ways of
                                        thinking to  improve the quality
                                        and efficiency of my code. 
                                        I am still looking for new ways
                                        to challenge myself and 
                                        better my abilities.
                                    </div>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <br/>
                        </div>
                    </Container>
                // </Spring>)} 
            // </VisibilitySensor>
        )
    }
}

export default Skills;