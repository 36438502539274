import React, { Component } from 'react'
import "../css/projects.css"
import { Container, Row, Col } from 'react-bootstrap';

class Projects extends Component {
    render() {
        return (
            <Container id="projects">
                <Row>
                    <Col>
                        <div style={{paddingTop: "80px"}}></div>
                    </Col>
                </Row>
                <div style={{backgroundColor: "#db4961"}}>
                    <br></br>
                    <Row>
                        <Col xs={2} md={2}>
                            <div className="projects-title">
                                <span>PROJECTS</span>
                            </div>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col xs={12} md={7}>
                                        <div className="projects-subtitle">
                                            <span>Compiler</span>
                                            <div className="projects-text">
                                                A Java Compiler written in Java that interprets and compiles LLVM Java code utilizing best practices for parsing, interpreting and compiling.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={{span: 7, offset: 5}}>
                                        <div className="projects-subtitle">
                                            <span>Appointments Webpage</span>
                                            <div className="projects-text">
                                               Webpage desgined with React and JavaScript utilizing a REST API to display information about appointments. It makes use of a MySQL database to store all information about appointments.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={7}>
                                        <div className="projects-subtitle">
                                            <span>Suduko Solver</span>
                                            <div className="projects-text">
                                                Suduko solving program written in python that makes use of the AC-3 algorithm along with backtracking algorithm to effectivly solve a given Suduko
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <br/>
                </div>
            </Container>
        )
    }
}

export default Projects;