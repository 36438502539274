import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import "../css/home.css"
import { Container, Row, Col } from 'react-bootstrap'
import { Spring } from 'react-spring/renderprops'

class Home extends Component {
    constructor() {
        super();
        this.state = {
            top: true
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        // console.log(window.scrollY);
        if (window.scrollY !== 0) {
            this.setState({
                top: false
            });

        } else {
            this.setState({
                top: true
            });
        }
    }

    render() {
        if (this.state.top) {
            return (
                <Container>
                    <Navbar/>
                    <div className="home-container">
                        <br></br>
                        <Spring 
                            config={{duration: 1500, delay: 500}}
                            from={{opacity: 0}} 
                            to={{opacity: 1}}>
                        {props =>
                            <Row>
                                <Col xs={12} style={props}>
                                    <div className="home-title">
                                        <span>Welcome</span>
                                        <span style={{color: "#84b8b2"}}>!</span>
                                    </div>  
                                </Col>
                            </Row>
                        }</Spring>
                        <Row className="home-text">
                            <Col xs={12} md={12} >
                                <div id="wrapper">My name is <span style={{color: "black"}}>Nicolas Ross</span>.</div>
                                <div id="wrapper">I am a <span style={{color: "black"}}>Software Developer</span> with a passion to create new and interesting programs utilizing my problem solving skills, relevant experiences and overall interest in software development.</div>                            </Col>
                        </Row>
                        <Row/>
                    </div>
                </Container>
            )
     
        } else {
            return (
                <Container>    
                    <Navbar/>
                    <div className="extended">
                        <br></br>
                        <Spring 
                            config={{duration: 800}}
                            from={{opacity: 1}} 
                            to={{opacity: 0}}>
                        {props =>
                            <Row>
                                <Col xs={12} style={props}>
                                    <div className="home-title">
                                        <span>Welcome</span>
                                        <span style={{color: "#84b8b2"}}>!</span>
                                    </div>  
                                </Col>
                            </Row>
                        }</Spring>
                        <Row className="home-text">
                            <Col xs={12} md={12}>
                                <div id="wrapper">My name is <span style={{color: "black"}}>Nicolas Ross</span>.</div>
                                <div id="wrapper">I am a <span style={{color: "black"}}> Software Developer</span> with a passion to create new and interesting programs utilizing my problem solving skills, relevant experiences and overall interest in software development.</div>
                            </Col>
                        </Row>
                        <Row/>
                    </div>
                </Container>
            )
        }
    }
}

export default Home;