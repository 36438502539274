import React, { Component } from 'react'
import { Link } from 'react-scroll'
import { Container, Row, Col } from 'react-bootstrap'
import "../css/navbar.css"

class Home extends Component {
    state = {};
    render() {
        return (
            <Container id="navbar">
                <Row style={{x: "100px"}}>
                    <Col xs={4}>
                        <div className="logo">NR</div>
                    </Col>
                    <Col xs={8} className="d-none d-sm-block" sm={8} m ={{ span: 6, offset: 2}} style={{paddingRight: "0"}}>
                        <nav className="nav">
                            <div className="nav-main">
                                <ul className="list">
                                    <Link 
                                        className="nav-item"
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={500}
                                        >
                                        CONTACT
                                    </Link>
                                    <Link 
                                        className="nav-item"
                                        activeClass="active"
                                        to="projects"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={500}
                                        >
                                        PROJECTS
                                    </Link>
                                    <Link 
                                        className="nav-item"
                                        activeClass="active"
                                        to="skills"
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={500}
                                        >
                                        SKILLS
                                    </Link>
                                </ul>
                            </div>
                        </nav> 
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Home;