import React, { Component } from 'react'
import "../css/contact.css"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll'

class Contact extends Component {
    render() {
        return (
            <Container id="contact">
                <Row>
                    <Col md={12}>
                        <div className="contact-title">
                            <span>CONTACT</span>
                        </div>
                    </Col>
                </Row>
                <div style={{backgroundColor: "#e4bb67"}}>
                    <br></br>
                    <Row xs={2} md={2} style={{marginTop: "20px"}}>
                        <Col xs={2} md={6} style={{textAlign: "center"}}>
                            <div className="font-size1 contact-subtitle">
                                <span>Email</span>
                            </div>
                        </Col> 
                        <Col xs={1} md={6}>
                            <div className="font-size2 contact-subtitle">
                                <span>g.nicolas.ross@gmail.com</span>
                            </div>
                        </Col>
                    </Row>
                    <Row md={2} style={{marginTop: "20px"}}>
                        <Col xs={2} md={6} style={{textAlign: "center"}}>
                            <div className="font-size1 contact-subtitle">
                                <span>Links</span>
                            </div>
                        </Col> 
                        <Col xs={2} md={6}>
                            <div className="font-size2 contact-subtitle">
                                <a 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    href="https://github.com/NicolasRoss" 
                                    className="links">
                                    GitHub
                                </a>
                            </div>
                            <div className="font-size2 contact-subtitle">
                                <a 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/in/nicolas-ross/" 
                                    className="links">
                                    LinkedIn
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row md={12} style={{marginTop: "30px"}}>
                        <Col>
                            <div style={{textAlign: "center"}} >
                                <Link
                                    className="back-to-top"
                                    activeClass="active"
                                    to="navbar"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                    >
                                    Back to Top
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <br></br>
                </div>
            </Container>
        )
    }
}

export default Contact;
